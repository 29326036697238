const randomName = () => {
	const adjectives = [
		'Oliver',
		'Emma',
		'Liam',
		'Ava',
		'Noah',
		'Sophia',
		'William',
		'Isabella',
		'James',
		'Mia',
		'Benjamin',
		'Charlotte',
		'Elijah',
		'Amelia',
		'Lucas',
		'Harper',
		'Henry',
		'Evelyn',
		'Alexander',
		'Abigail',
		'Michael',
		'Emily',
		'Ethan',
		'Elizabeth',
		'Daniel',
		'Sofia',
		'Matthew',
		'Avery',
		'Aiden',
		'Ella',
		'Joseph',
		'Scarlett',
		'Samuel',
		'Grace',
		'David',
		'Chloe',
		'Carter',
		'Victoria',
		'Logan',
		'Riley',
		'Gabriel',
		'Zoey',
		'Jackson',
		'Lily',
		'Anthony',
		'Hannah',
		'John',
		'Natalie',
		'Dylan',
		'Layla',
	];

	const emojis = [
		'🙂',
		'😀',
		'😂',
		'😍',
		'😎',
		'😊',
		'🔥',
		'🌞',
		'🍀',
		'🍎',
		'🍕',
		'🍔',
		'🚀',
		'🚗',
		'⚽',
		'🏀',
		'🎸',
		'🎨',
		'📚',
		'🎮',
		'🎁',
		'💼',
		'💡',
		'🔒',
		'⏰',
		'🌍',
		'🏝️',
		'🌊',
		'🐶',
		'🐱',
		'🐼',
		'🐵',
		'🐸',
		'🐢',
		'🦋',
		'🌺',
		'🌼',
		'🌻',
		'🍦',
		'🍩',
	];

	const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
	const emoji = emojis[Math.floor(Math.random() * emojis.length)];
	return emoji + '  ' + adjective;
};

const randomColor = () => {
	return '#' + Math.floor(Math.random() * 0xffffff).toString(16);
};

export { randomName, randomColor };
